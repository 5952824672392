import { Table } from 'react-bootstrap';
import './StockTable.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import StockRow from './StockRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import StockRowView from './StockRowView';

const StockTableView = ({newStock, setNewStock, edittingBorder, setEdittingBorder, setSuggestionError, cancelEdition, showAllEntries}) => {
  const { products, stock } = useContext(ProductsContext);
  const [dataShowed, setDataShowed] = useState([]);
  const [pendingUploadStock, setPendingUploadStock] = useState([]);
  
  const handleClick = (entry, index) => {
    setNewStock({
      itemId: entry.itemId,
      itemName: products.find(item => item._id === entry.itemId)?.productName.toLowerCase(),
      wholePrice: entry.wholePrice,
      wholeQuantity: entry.wholeQuantity,
      salesPricePerUnit: entry.salesPricePerUnit,
      waste: entry.waste,
      onEdition: true,
      editionId: entry._id,
      stockSign: entry.sign,
      pendingUpload: entry.pendingUpload,
      bultos: entry.bultos
    })
    setEdittingBorder(index.toString());
    setSuggestionError(false);
  }

  useEffect(() => {
    if(showAllEntries){
      setDataShowed(stock.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    }else{
      setDataShowed(stock.filter(entry => !entry.published && !entry.pendingUpload).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    }
  }, [stock])

  return (
    <>
      {
        dataShowed.length !== 0 && showAllEntries !== true && dataShowed.filter(item => !item.pendingUpload).length !== 0 ? (
          <small className='text-muted fst-italic text-spinner-effect'>Por favor, aguarde a la publicación del producto. Tiene 1 minuto desde el momento de la primera publicación para corregir algún error...</small>
        ) : null
      }
      
      <Table bordered striped className={dataShowed.length === 0 && pendingUploadStock.length === 0 ? 'd-none' : null}>
        <thead>
          <tr>
            {
              showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Entrada <span><FontAwesomeIcon className='ms-2' icon={ faArrowDown } /></span></div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-start h-100">Producto</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Cantidad</div></th>
            {
              showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Remanente</div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Costo [$]</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Precio Venta [$]</div></th>
            {
              !showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Entrada</div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Publicado</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100"></div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100"></div></th>
          </tr>
        </thead>
        <tbody>
          {
            dataShowed?.map((entry, index) => <StockRowView entry={entry} handleClick={handleClick} edittingBorder={edittingBorder} key={index} index={index} cancelEdition={cancelEdition} newStock={newStock} showAllEntries={showAllEntries} />)
          }
        </tbody>
      </Table>
    </>
  );
};

export default StockTableView;