import { Table } from 'react-bootstrap';
import './VoucherFilteredTable.css';
import { useEffect, useState } from 'react';

const VoucherFilteredTable = ({showedData, setShowedData, changeFilter, showGroupTableBoolean}) => {
  const [allUsersChecked, setAllUsersChecked] = useState(false);

  const handleChange = (e, userEmail) => {
    if(e.target.name === "userChecked"){
      const data = showedData.concat([]);
      const index = data.findIndex(item => item.userEmail === userEmail);
      data[index].userChecked = e.target.checked;
      setShowedData(data);
    }
  }

  useEffect(() => {
    const data = showedData.concat([]);
    for(let i = 0; i < data.length; i++){
      document.getElementById(`userChecked-${i}`).checked = allUsersChecked;
    }
    setShowedData(showedData.map(item => ({...item, userChecked: allUsersChecked})).concat([]));
  }, [allUsersChecked])

  useEffect(() => {
    if(allUsersChecked){
      document.getElementById('userChecked').checked = false;
      setAllUsersChecked(false);
    }
  }, [changeFilter, showGroupTableBoolean])

  return (
    <Table striped bordered hover className='voucherFilteredTable-style'>
      <thead>
        <tr>
          <th className='text-center'><input type="checkbox" id="userChecked" onChange={() => setAllUsersChecked(!allUsersChecked)} value={allUsersChecked} /></th>
          <th className='text-center'>#</th>
          <th className='text-center'>Usuario</th>
          <th className='text-center'>Pedidos</th>
          <th className='text-center'>Último Pedido</th>
          <th className='text-center'>$$</th>
        </tr>
      </thead>
      <tbody>
        {showedData?.map((userData, index) => (
          <tr key={index}>
            <td className='text-center'><input type="checkbox" id={`userChecked-${index}`} name='userChecked' onChange={(e) => handleChange(e, userData.userEmail)} value={userData.userChecked} /> </td>
            <td>{index+1}</td>
            <td>{userData.userEmail}</td>
            <td className='text-center'>{userData.orders.length > 0 ? userData.orders.length : "0"}</td>
            <td className='text-center'>{userData.orders.length > 0 ? userData.orders[userData.orders.length-1].createdAt.substring(0, 10) : "-"}</td>
            <td className='text-center text-success fw-bold'>{userData.orders.length > 0 ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(userData.orders.map(item => item.totalPrice).reduce((a, b) => a+b, 0))) : "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VoucherFilteredTable;