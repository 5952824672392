import './VoucherPage.css';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import axiosClient from '../../settings/axiosClient';
import { useEffect, useState } from 'react';
import AddVoucherTable from '../../components/AddVoucherTable/AddVoucherTable';
import VoucherTable from '../../components/VoucherTable/VoucherTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Spinner from '../../components/Spinner/Spinner';
import PopUp from '../../components/PopUp/PopUp';
import AddGroupVoucher from '../../components/AddGroupVoucher/AddGroupVoucher';
import { Form } from 'react-bootstrap';
import { firstUpperCase } from '../../helpers/firstUpperCase';

const VoucherPage = () => {
  const [users, setUsers] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [errors, setErrors] = useState({});
  const [tableBoolean, setTableBoolean] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [showGroupTableBoolean, setShowGroupTableBoolean] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [message, setMessage] = useState('');
  
  const getData = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const usersResponse = await axiosClient.get('/users/usersemail');
      if (usersResponse.status === 200) {
        setUsers(usersResponse.data.filter(user => user.adminKey === undefined));
      }
      const vouchersResponse = await axiosClient.get('/vouchers');
      if (vouchersResponse.status === 200) {
        setVouchers(vouchersResponse.data.reverse());
      }
      const ordersResponse = await axiosClient.get('/orders/voucher');
      if (ordersResponse.status === 200) {
        setAllOrders(ordersResponse.data);
      }
      setSuccess(true);
    } catch (error) {
      setErrors({servidor: 'Error en el servidor. Intente nuevamente.'});
    }
  }
  
  const showTable = () => {
    if(showGroupTableBoolean) setShowGroupTableBoolean(false);
    if(tableBoolean){
      setTableBoolean(false);
    }else{
      setTableBoolean(true);
    }
  }

  const showGroupTable = () => {
    if(tableBoolean) setTableBoolean(false);
    if(showGroupTableBoolean){
      setShowGroupTableBoolean(false);
    }else{
      setShowGroupTableBoolean(true);
    }
  }

  const sendMessage = async (voucher, whatsappMessage) => {
    setSpinner(true);
    const user = users.find(user => user.userEmail === voucher.voucherOwner);
    const messageUpdatedSupSup = message.replace('{user}', user ? `${firstUpperCase(user.userShortName)} ¿Cómo estás?` : '¿Cómo estás?');
    let messageUpdatedSup;
    if(voucher.discountType === "$"){
      messageUpdatedSup = messageUpdatedSupSup.replace('{voucher}', new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(voucher.voucherDiscount)));
    }else{
      messageUpdatedSup = messageUpdatedSupSup.replace('{voucher}', `${voucher.voucherDiscount}%`);
    }
    const messageUpdated = messageUpdatedSup.replace('{code}', voucher.voucherCode);
    try {
      const response = await axiosClient.post('/message/voucher', {user, message: messageUpdated, userEmail: voucher.voucherOwner, whatsappMessage});
      if(response.status === 200){
        if(users.find(user => user.userEmail === voucher.voucherOwner)){
          setUsers(users.map(user => user.userEmail === voucher.voucherOwner ? {...user, lastWsp: response.data.userUpdated.lastWsp, lastEmail: response.data.userUpdated.lastEmail, lastMessage: response.data.userUpdated.lastMessage} : user));
        }else{
          setUsers([...users, response.data.userUpdated]);
        }
        setSuccess(true);
      }
    } catch (error) {
      setErrors({servidor: 'Error en el servidor. Intente nuevamente.'});
    }
  }

  useEffect(() => {
    if(users.length === 0 || vouchers.length === 0){
      getData();
    }
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000)
    }
  }, [errors])

  useEffect(() => {
    if(!errorPopUp && Object.keys(errors).length !== 0){
      setErrors({});
    }
  }, [errorPopUp])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <Layout>
      <AdminRoute>
        <div className='voucherPage-style pt-3'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div className='voucher-form-style d-flex flex-column align-items-center'>
            <div className='bg-light w-100 voucher-tytle-box py-1 rounded text-center pointer' onClick={showTable}>Generación de Cupones Individuales<span className='ms-1' ><FontAwesomeIcon icon={tableBoolean ? faAngleUp : faAngleDown} /></span></div>
            <div className={`table-container mt-3 ${!tableBoolean ? 'd-none' : null}`}>
              <AddVoucherTable users={users} vouchers={vouchers} setVouchers={setVouchers} />
            </div>
            <div className='mt-3 bg-light w-100 voucher-tytle-box py-1 rounded text-center pointer' onClick={showGroupTable}>Generar cupones por Grupos<span className='ms-1' ><FontAwesomeIcon icon={showGroupTableBoolean ? faAngleUp : faAngleDown} /></span></div>
            <div className={`table-container mt-3 ${!showGroupTableBoolean ? 'd-none' : null}`}>
              <AddGroupVoucher users={users} vouchers={vouchers} setVouchers={setVouchers} spinner={spinner} setSpinner={setSpinner} setShowGroupTableBoolean={setShowGroupTableBoolean} showGroupTableBoolean={showGroupTableBoolean} allOrders={allOrders} getData={getData} />
            </div>
            <div className='mt-5 bg-light w-100'>
              <Form.Group>
                <Form.Label className='fw-bold'>Mensaje Recordatorio</Form.Label>
                <Form.Control placeholder='Es necesario redactar el mensaje para enviar el recordatorio...' as="textarea" rows={3} onChange={(e) => setMessage(e.target.value)} value={message} />
              </Form.Group>
              <small>[{message.length}/400]</small>
              <div><small className='text-secondary fst-italic'>Aclaraciones: {'{user}: Nombre / {voucher}: Monto del voucher / {code}: Codigo del voucher / {user} se reemplaza por: {user} ¿Cómo estás?'}</small></div>

            </div>
            <div className='w-100 text-center mt-5 fw-bold'>CUPONES ENTREGADOS</div>
            <VoucherTable vouchers={vouchers} sendMessage={sendMessage} users={users} message={message} />
          </div>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} closeBtn={true} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"} />
      </AdminRoute>
    </Layout>
  );
};

export default VoucherPage;