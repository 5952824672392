import React, { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import StockSpinner from '../stockSpinner/StockSpinner';

const StockRow = ({entry, handleClick, edittingBorder, index, cancelEdition, newStock, showAllEntries, setErrorPopUp, errors, setErrors, publishTimerActive}) => {
  const { products, deleteStockEntry, publishEntry } = useContext(ProductsContext);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const WAITING_TIME = 1*60000; //1 minute

  const deleteStock = async (stockId) => {
    const alert = window.confirm("¿Está seguro que desea eliminar esta entrada?");
    if(alert){
      setSpinner(true);
      const deleteErrors = await deleteStockEntry(stockId);
      if(Object.keys(deleteErrors).length !== 0){
        return setErrors(deleteErrors);
      }
      setSuccess(true);
    }
  }

  useEffect(() => {
    if(!publishTimerActive) return;
    let publishTimer;
    if(!entry.published && !entry.pendingUpload){
      setSpinner(true);
      const createdTime = new Date(entry.createdAt).getTime();
      const timeNow = new Date().getTime();
      if((createdTime+WAITING_TIME) > timeNow){
        publishTimer = setTimeout(() => {
          publishEntry(entry._id, entry.itemId);
          setSuccess(true);
        }, (createdTime+WAITING_TIME) - timeNow)
      }else{
        publishEntry(entry._id, entry.itemId);
        setSuccess(true);
      }
    }

    return () => clearTimeout(publishTimer);
  }, [entry])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      if(showAllEntries !== true){
        if(newStock.onEdition) cancelEdition();
      }
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    };
  }, [errors])

  return (
    <tr key={index} className={edittingBorder === index.toString() ? "border border-warning thick-border" : null}>
      {
        showAllEntries ? (
          <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{entry.createdAt.substring(0, 10)} {Number(entry.createdAt.substring(11, 13)-3)+entry.createdAt.substring(13, 16)}hs</td>
        ) : null
      }
      <td className={entry.wholeQuantityUpdated === 0 ? "text-danger" : null}>{products.find(item => item._id === entry.itemId)?.productName}</td>
      <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{entry.wholeQuantity} {entry.unit}</td>
      {
        showAllEntries ? (
          <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{Number(entry.wholeQuantityUpdated).toFixed(2)} {entry.unit}</td>
        ) : null
      }
      <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.wholePrice))}</td>
      <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.salesPricePerUnit))} /{entry.unit}</td>
      {
        !showAllEntries ? (
          <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{entry.createdAt.substring(0, 10)} {Number(entry.createdAt.substring(11, 13)-3)+entry.createdAt.substring(13, 16)}hs</td>
        ) : null
      }
      <td className={`text-center wpx-70 ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{spinner ? <StockSpinner /> : (entry.pendingUpload ? "PENDIENTE" : "SI")}</td>
      <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}><span className={`p-1 ${spinner || entry.pendingUpload ? "delete-item text-warning" : "delete-item_disabled"} ${showAllEntries ? "d-none" : null}`} onClick={spinner || entry.pendingUpload ? () => handleClick(entry, index) : null}><FontAwesomeIcon icon={faPenToSquare} /></span></td>
      <td className={`text-center ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{entry.pendingUpload ? <span className='text-danger pointer' onClick={() => deleteStock(entry._id)}>X</span> : null}</td>
    </tr>
  );
};

export default StockRow;