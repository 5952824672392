import { useContext, useEffect, useState } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import { Table } from "react-bootstrap";

const ProductData = ({stockEntrySelected, wastes}) => {
  const { stock, products } = useContext(ProductsContext);
  const [itemStock, setItemStock] = useState([]);

  useEffect(() => {
    if(Object.keys(stockEntrySelected).length !== 0){
      const stockPrepared = stock.filter(item => item.itemId === stockEntrySelected.itemId).map(item => ({
        ...item,
        daysStoraged: (new Date(item.updatedAt).getTime() - new Date(item.createdAt).getTime())/(24*60*60*1000),
        wholeSales: (item.wholeQuantity - item.wholeQuantityUpdated - wastes.filter(i => i.stockId === item._id).reduce((a, b) => a + b.quantity, 0)) * item.salesPricePerUnit,
        waste: wastes.filter(i => i.stockId === item._id).reduce((a, b) => a + b.quantity, 0)
      }))
      setItemStock(stockPrepared.filter(item => !item.splittedFrom).map(item => {
        const stockSplitted = stockPrepared.filter(entry => entry.splittedFrom === item._id);
        if(stockSplitted.length !== 0){
          return {
            ...item,
            wholeQuantity: [item, ...stockSplitted].reduce((acc, entry) => acc + entry.wholeQuantity, 0),
            wholeQuantityUpdated: [item, ...stockSplitted].reduce((acc, entry) => acc + entry.wholeQuantityUpdated, 0),
            wholePrice: [item, ...stockSplitted].reduce((acc, entry) => acc + entry.wholePrice, 0),
            wholeSales: [item, ...stockSplitted].reduce((acc, entry) => acc + entry.wholeSales, 0),
            waste: [item, ...stockSplitted].reduce((acc, entry) => acc + entry.waste, 0),
            lastSalesPrice: stockSplitted[stockSplitted.length - 1]?.salesPricePerUnit ?? 0,
          }
        }else{
          return {
            ...item,
            lastSalesPrice: item.salesPricePerUnit
          };
        }
      }));
    }
  }, [stockEntrySelected])

  return (
    <div className="w-100 my-3">
      <p className="mb-0 fw-bold text-success bg-light">{products.find(product => product._id === stockEntrySelected.itemId)?.productName}</p>
      <Table striped bordered className="addProduct-table-style mb-0">
        <thead>
          <tr>
            <th></th>
            <th className="text-center">Entrada</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Bultos</th>
            <th className="text-center">Remanente</th>
            <th className="text-center">Duración [dias]</th>
            <th className="text-center">Desperdicio</th>
            <th className="text-center">Último $ Venta</th>
            <th className="text-center">Costo</th>
            <th className="text-center">Venta</th>
            <th className="text-center">[%]</th>
          </tr>
        </thead>
        <tbody>
          {
            itemStock?.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{item.createdAt.substring(0, 10).split('-').reverse().join('/').substring(0, 5)}</td>
                <td className="text-center">{Number(item.wholeQuantity).toFixed(2)}</td>
                <td className="text-center">{item.bultos && item.bultos !== 0 ? Number(item.bultos).toFixed(2) : "-"}</td>
                <td className="text-center">{Number(item.wholeQuantityUpdated).toFixed(2)}</td>
                <td className="text-center">{item.wholeQuantityUpdated === 0 ? <span>{Number(item.daysStoraged).toFixed(2)}</span> : <span className="text-warning">{Number((new Date().getTime() - new Date(item.createdAt).getTime())/(24*60*60*1000)).toFixed(2)}</span>}</td>
                <td className="text-center">{Number(item.waste).toFixed(2)}</td>
                <td className="text-center">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(item.lastSalesPrice))}</td>
                <td className="text-center">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(item.wholePrice))}</td>
                <td className="text-center">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(item.wholeSales))}</td>
                <td className={`text-center ${item.wholeSales/item.wholePrice >= 1 ? "text-success" : "text-danger"}`}>{Number(((item.wholeSales/item.wholePrice)-1)*100).toFixed(2)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <p className="fst-italic fs-6 mt-0"><span className="fw-bold">Obs: </span>Los costos de la mercadería no incluyen la comisión del proveedor.</p>
    </div>
  );
};

export default ProductData;