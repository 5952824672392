import './StockAgePage.css';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import { Fragment, useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import { Spinner, Table } from 'react-bootstrap';
import { firstUpperCase } from '../../helpers/firstUpperCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDivide, faEdit, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../../components/PopUp/PopUp';

const StockAgePage = () => {
  const { stock, getStock, products, getAllProducts, splitStock, updateSalesPrice } = useContext(ProductsContext);
  const [spinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [stockOnEdition, setStockOnEdition] = useState({});
  const [indexSelected, setIndexSelected] = useState(-1);
  const [errorPopUp, setErrorPopUp] = useState(false);

  const getStockFunction = async () => {
    const getStockErrors = await getStock();
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getStockErrors). length !== 0 || Object.keys(getProductsErrors). length !== 0){
      if(Object.keys(getProductsErrors). length !== 0){
        setErrors(getProductsErrors);
      }else{
        setErrors(getStockErrors);
      }
      return;
    };
    setSuccess(true);
  }

  const handleKeyDown = (e, stockId) => {
    if(e.keyCode === 27){
      cancelSplit(stockData.find(item => item._id === stockId));
      e.target.blur();
    }
  }

  const handleChange = (e, stockId) => {
    setStockData(stockData.map(item => item._id === stockId ? {...item, salesPriceUpdate: Number(e.target.value)} : item));
  }

  const splitStockFunction = (stock, index) => {
    setIndexSelected(index);
    if(stock.wholeQuantityUpdated === 0) return;
    setStockOnEdition(stock);
    const stockSplitted = stockData.map(item => item._id === stock._id ? {...item, wholeQuantity: Number(Math.round((stock.wholeQuantity-stock.wholeQuantityUpdated)*100)/100), wholeQuantityUpdated: 0, wholePrice: Number(Math.round(((stock.wholeQuantity-stock.wholeQuantityUpdated)*stock.wholePrice/stock.wholeQuantity)*100)/100), onEdition: true} : item);
    const newStock = {...stock, _id: undefined, wholeQuantity: Number(Math.round(stock.wholeQuantityUpdated*100)/100), wholeQuantityUpdated: Number(Math.round(stock.wholeQuantityUpdated*100)/100), wholePrice: Number(Math.round((stock.wholeQuantityUpdated*stock.wholePrice/stock.wholeQuantity)*100)/100), splittedFrom: stock.splittedFrom ? stock.splittedFrom : stock._id, onEdition: true};
    stockSplitted.splice(index+1, 0, newStock)
    setStockData(stockSplitted);
  }

  const cancelSplit = (stock) => {
    if(Object.keys(stockOnEdition).length === 0){
      const stockCanceled = stockData.map(item => item._id === stock._id ? {...item, salesPriceUpdate: undefined} : item);
      setStockData(stockCanceled);
      return setIndexSelected(-1);
    } 
    const stockCanceled = stockData.map(item => item._id === stock._id ? stockOnEdition : item);
    setStockData(stockCanceled.filter(item => !item.onEdition));
    setStockOnEdition({});
    setIndexSelected(-1);
  }

  const sendData = async () => {
    setSpinner(true);
    const stockToSend = stockData.filter(item => item.onEdition);
    const splitStockErrors = await splitStock(stockToSend);
    if(Object.keys(splitStockErrors).length !== 0){
      return setErrors(splitStockErrors);
    };
    setIndexSelected(-1);
    setStockOnEdition({});
    setSuccess(true);
  }

  const salePriceUpdate = async (stock) => {
    setSpinner(true);
    const salesPriceUpdateError = await updateSalesPrice(stock._id, Number(Math.round(stock.salesPriceUpdate*100)/100));
    if(Object.keys(salesPriceUpdateError).length !== 0){
      setErrors(salesPriceUpdateError);
      return;
    };
    setIndexSelected(-1);
    setStockOnEdition({});
    setSuccess(true);
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(stock.length === 0 || products.length === 0){
      setSpinner(true);
      getStockFunction();
    }
  }, [])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  useEffect(() => {
    if(stock.length !== 0 && products.length !== 0){
      const stockSup = stock.filter(stock => stock.wholeQuantityUpdated > 0);
      setStockData(stockSup.map(stock => ({
        ...stock,
        itemName: products.find(product => product._id === stock.itemId)?.productName,
        daysSinceEntry: Number((new Date().getTime() - new Date(stock.createdAt).getTime())/(24*60*60*1000)).toFixed(1)
      })).sort((a, b) => b.daysSinceEntry - a.daysSinceEntry));
    };
  }, [stock, products])

  useEffect(() => {
    if(indexSelected !== -1){
      if(Object.keys(stockOnEdition).length !== 0){
        document.getElementById(`in-${indexSelected+1}`).select();
      }else{
        document.getElementById(`in-${indexSelected}`).select();
      }
    }
  }, [indexSelected])

  return (
    <Layout>
      <AdminRoute>
        <div className='stockAgePage-style pt-5 px-2'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div><small className='fst-italic'>El costo Unitario de frutas y verduras ya está cargado con el costo del proveedor.</small></div>
          <Table striped bordered hover>
            <thead>
              <tr className='position-sticky top-0 z-50'>
                <th>Entrada</th>
                <th>Producto</th>
                <th className='text-center'>Días</th>
                <th className='text-center'>Un.</th>
                <th className='text-center'>Q lote</th>
                <th className='text-center'>Remanente</th>
                <th className='text-center'>Costo/U</th>
                <th className='text-center'>Venta/U</th>
                <th className='text-center'>[%]</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                stockData?.map((stock, index) => (
                  <Fragment key={index}>
                    <tr className={stock.onEdition ? "border-2 border-warning" : ""}>
                      <td className={indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}>{stock.createdAt.substring(5, 10).split('-').reverse().join('/')}</td>
                      <td className={indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}>{ indexSelected === index ? stock.itemName : firstUpperCase(stock.itemName)}</td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{stock.daysSinceEntry}</td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{stock.unit}</td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{Number(stock.wholeQuantity).toFixed(2)}</td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{Number(stock.wholeQuantityUpdated).toFixed(2)}</td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{Number(stock.wholePrice*(products.find(i => i._id === stock.itemId)?.category === "dried" ? 1 : 1.1)/stock.wholeQuantity).toFixed(2)}</td>
                      <td className='text-center p-0 wpx-60'>
                        <div className="d-flex align-items-center justify-content-center input-group hpx-40">
                          <input id={`in-${index}`} autoComplete="off" disabled={Object.keys(stockOnEdition).length !== 0 ? (indexSelected + 1) !== index : indexSelected !== index} className={`text-center form-control input-noborder w-100 h-100 ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`} type="number" value={stock.salesPriceUpdate ? stock.salesPriceUpdate : stock.salesPricePerUnit} onKeyDown={(e) => handleKeyDown(e, stock._id)} onChange={(e) => handleChange(e, stock._id)} />
                        </div>
                      </td>
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}>{((((stock.salesPriceUpdate ? stock.salesPriceUpdate : stock.salesPricePerUnit) / (stock.wholePrice*(products.find(i => i._id === stock.itemId)?.category === "dried" ? 1 : 1.1)/stock.wholeQuantity))-1 )* 100).toFixed(2)}</td>
                      
                      <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}><span className='pointer'>
                        {
                          stock.wholeQuantity === stock.wholeQuantityUpdated && !stock.onEdition && indexSelected === -1 ? <FontAwesomeIcon icon={faEdit} onClick={() => setIndexSelected(index)} /> : (
                            stock.wholeQuantity === stock.wholeQuantityUpdated && !stock.onEdition && indexSelected === index ? <span className='pointer text-danger' onClick={() => cancelSplit(stock)}>X</span> : ""
                          )
                        }
                      </span></td>
                      {
                        stock._id ? (
                          <>
                            {
                              index === indexSelected && Object.keys(stockOnEdition).length !== 0 ? (
                                <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}><span className='pointer text-danger' onClick={() => cancelSplit(stock)}>X</span></td>
                              ) : (
                                <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}><span className='pointer'>{stock.wholeQuantity === stock.wholeQuantityUpdated ? (indexSelected !== -1 ? <FontAwesomeIcon icon={faPaperPlane} onClick={() => salePriceUpdate(stock)} /> : "") : <FontAwesomeIcon onClick={() => splitStockFunction(stock, index)} icon={faDivide} />}</span></td>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <td className={`text-center ${indexSelected !== -1 && indexSelected !== index && stock._id ? "text-secondary" : ""}`}><span className='pointer text-success' onClick={sendData}><FontAwesomeIcon icon={faPaperPlane} /></span></td>
                          </>
                        )
                      }
                    </tr>
                  </Fragment>
                ))
              }
            </tbody>
          </Table>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default StockAgePage;