import { Table } from 'react-bootstrap';
import './VoucherTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const VoucherTable = ({ vouchers, sendMessage, users, message }) => {
  const TIME_LIMIT = 24 * 60 * 60 * 1000;
  return (
    <>
      <div className='w-100 px-3 text-start fst-italic'>Se deben esperar al menos 24hs para poder volver a enviar un recordatorio a los usuarios</div>
      <Table striped bordered hover className='voucherTable-style'>
        <thead className='border border-dark'>
          <tr>
            <th className='text-center'>#</th>
            <th className='text-center'>Usuario</th>
            <th className='text-center'>Descuento</th>
            <th className='text-center'>Validez</th>
            <th className='text-center'>Uso</th>
            <th className='text-center'>Ultimo Wsp</th>
            <th className='text-center'>Ultimo Email</th>
            <th className='text-center'>Recordar</th>
          </tr>
        </thead>
        <tbody>
          {
            vouchers?.map((voucher, index) => {
              const user = users.find(user => user.userEmail === voucher.voucherOwner);
              const wspDisabled = user && user.lastWsp && (new Date().getTime() - new Date(user.lastWsp).getTime()) < TIME_LIMIT;
              const emailDisabled = user && user.lastEmail && (new Date().getTime() - new Date(user.lastEmail).getTime()) < TIME_LIMIT;
              return (
                <tr key={index}>
                  <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{vouchers.length - index}</td>
                  <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.voucherOwner}</td>
                  <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.discountType === '$' ? `${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(voucher.voucherDiscount))}` : `${Number(voucher.voucherDiscount).toFixed(2)}${voucher.discountType}`}</td>
                  <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.validDate.split('-').reverse().join('/').substring(0, 5)}</td>
                  <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.voucherUsed ? "Usado" : "Pendiente"}</td>
                  <td className={`${user?.lastMessage ? "text-decoration-underline pointer" : ""} ${voucher.valid ? "bg-valid" : "bg-notvalid"}`} onClick={() => console.log(user?.lastMessage ?? "")}>{user?.lastWsp ? user?.lastWsp.substring(0, 10).split("-").reverse().join('/').substring(0, 5) : ""}</td>
                  <td className={`${user?.lastMessage ? "text-decoration-underline pointer" : ""} ${voucher.valid ? "bg-valid" : "bg-notvalid"}`} onClick={() => console.log(user?.lastMessage ?? "")}>{user?.lastEmail ? user?.lastEmail.substring(0, 10).split("-").reverse().join('/').substring(0, 5) : ""}</td>
                  <td className={`px-2 ${voucher.valid ? "bg-valid" : "bg-notvalid"}`}>
                    <div className={`d-flex align-items-center justify-content-center mt-1 ${!voucher.valid ? "invisible" : ""}`}>
                      <FontAwesomeIcon className={`${wspDisabled || message.length < 10 ? "text-secondary" : "pointer"} ${user?.userPhone ? "" : "invisible"}`} icon={faComment} onClick={wspDisabled || message.length < 10 ? () => {} : () => sendMessage(voucher, true)} />
                      <FontAwesomeIcon className={`ms-3 ${emailDisabled || message.length < 10 ? "text-secondary" : "pointer"}`} icon={faEnvelope} onClick={emailDisabled || message.length < 10 ? () => {} : () => sendMessage(voucher, false)} />
                    </div>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </>
  );
};

export default VoucherTable;