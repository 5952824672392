import { Table } from 'react-bootstrap';
import './StockTable.css';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import StockRow from './StockRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const StockTable = ({newStock, setNewStock, edittingBorder, setEdittingBorder, setSuggestionError, cancelEdition, showAllEntries, setErrorPopUp, errors, setErrors}) => {
  const { products, stock, publishEntry } = useContext(ProductsContext);
  const [dataShowed, setDataShowed] = useState([]);
  const [pendingUploadStock, setPendingUploadStock] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const handleClick = (entry, index) => {
    setNewStock({
      itemId: entry.itemId,
      itemName: products.find(item => item._id === entry.itemId)?.productName.toLowerCase(),
      wholePrice: entry.wholePrice,
      wholeQuantity: entry.wholeQuantity,
      salesPricePerUnit: entry.salesPricePerUnit,
      waste: entry.waste,
      onEdition: true,
      editionId: entry._id,
      stockSign: entry.sign,
      pendingUpload: entry.pendingUpload,
      bultos: entry.bultos
    })
    setEdittingBorder(index.toString());
    setSuggestionError(false);
  }

  const publishPendingEntries = async () => {
    if(pendingUploadStock.length === 0) return;
    const alert = window.confirm("¿Está seguro que desea cargar las entradas seleccionadas? IMPORTANTE: la carga de stock es inmediata");
    if(!alert) return;
    setSpinner(true);
    let error = false;
    for(let i=0; i<pendingUploadStock.length; i++){
      const publishError = await publishEntry(pendingUploadStock[i]._id, pendingUploadStock[i].itemId);
      if(Object.keys(publishError).length !== 0){
        error = true;
      }
    }
    if(error) return setErrors({server: "Error al cargar alguna/s de la/s entrada/s. Recargue la página e intente nuevamente."});
    setSuccess(true);
  }

  useEffect(() => {
    if(showAllEntries){
      setDataShowed(stock.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    }else{
      setDataShowed(stock.filter(entry => !entry.published && !entry.pendingUpload).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setPendingUploadStock(stock.filter(entry => entry.pendingUpload).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    }
  }, [stock])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setErrorPopUp(true);
      setSpinner(false);
    };
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <>
      {
        dataShowed.length !== 0 && showAllEntries !== true && dataShowed.filter(item => !item.pendingUpload).length !== 0 ? (
          <small className='text-muted fst-italic text-spinner-effect'>Por favor, aguarde a la publicación del producto. Tiene 1 minuto desde el momento de la primera publicación para corregir algún error...</small>
        ) : null
      }
      
      <Table bordered striped className={dataShowed.length === 0 && pendingUploadStock.length === 0 ? 'd-none' : null}>
        <thead>
          <tr>
            {
              showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Entrada <span><FontAwesomeIcon className='ms-2' icon={ faArrowDown } /></span></div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-start h-100">Producto</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Cantidad</div></th>
            {
              showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Remanente</div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Costo [$]</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Precio Venta [$]</div></th>
            {
              !showAllEntries ? (
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Entrada</div></th>
              ) : null
            }
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Publicado</div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100"></div></th>
            <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100"></div></th>
          </tr>
        </thead>
        <tbody>
          {
            dataShowed?.map((entry, index) => <StockRow entry={entry} handleClick={handleClick} edittingBorder={edittingBorder} key={index} index={index} cancelEdition={cancelEdition} newStock={newStock} showAllEntries={showAllEntries} setErrorPopUp={setErrorPopUp} errors={errors} setErrors={setErrors} publishTimerActive={true} />)
          }
          {
            pendingUploadStock?.map((entry, index) => <StockRow entry={entry} handleClick={handleClick} edittingBorder={edittingBorder} key={index} index={index} cancelEdition={cancelEdition} newStock={newStock} showAllEntries={showAllEntries} setErrorPopUp={setErrorPopUp} errors={errors} setErrors={setErrors} publishTimerActive={false} />)
          }
          <tr>
            <td colSpan={9} className='text-center'>
              <span className={`border rounded py-1 px-5 shadow-sm ${spinner ? "text-secondary" : "pending-btn pointer"}`} onClick={spinner ? () => {} : () => publishPendingEntries()}>CARGAR PENDIENTES SELECCIONADOS</span>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default StockTable;