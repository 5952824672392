import { useContext, useEffect, useState } from 'react';
import { getDayData } from '../../helpers/getDayData';
import './AddGroupVoucher.css';
import { Button, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlaneDeparture  } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../PopUp/PopUp';
import VoucherFilteredTable from '../VoucherFilteredTable/VoucherFilteredTable';
import { postGroupVoucher } from '../../helpers/postVoucher';
import UserContext from '../../context/Users/UserContext';
import { firstUpperCase } from '../../helpers/firstUpperCase';

const AddGroupVoucher = ({ users, vouchers, setVouchers, spinner, setSpinner, setShowGroupTableBoolean, showGroupTableBoolean, allOrders, getData }) => {
  const [validDates, setValidDates] = useState([]);
  const [defaultDate, setDefaultDate] = useState('');
  const [errors, setErrors] = useState({});
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [showedData, setShowedData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [changeFilter, setChangeFilter] = useState(false);
  const { sign } = useContext(UserContext);
  const [message, setMessage] = useState('');

  const makeDataForFilter = () => {
    let data = [];
    users.forEach(user => {
      const userData = {
        ...user,
        orders: allOrders.filter(order => order.owner === user.userEmail),
        userChecked: false
      }
      data.push(userData);
    });
    setShowedData(data);
  }

  const handleClick = async (channel) => {
    if(message.length === 0) return;
    if(spinner) return;
    setSpinner(true);
    const date = document.getElementById(`date`).value;
    const discountType1 = document.getElementById(`discountType%`).checked ? '%' : null;
    const discountType2 = document.getElementById(`discountType$`).checked ? '$' : null;
    const voucherDiscount = Number(document.getElementById(`voucherDiscount`).value);
    const minPurchase = Number(document.getElementById(`minPurchase`).value);
    let discountType;
    let clickErrors = {};

    if(discountType1 && !discountType2){
      discountType = discountType1;
    }else if(discountType2 && !discountType1){
      discountType = discountType2;
    }else{
      clickErrors.discountType = 'Introduzca un tipo de descuento';
    }

    if(voucherDiscount === undefined || voucherDiscount.toString() === 'NaN' || voucherDiscount === 0){
      clickErrors.voucherDiscount = 'Introduzca un valor de descuento valido';
    }

    if(minPurchase < 0 || minPurchase.toString() === "NaN") clickErrors.minPurchase = 'Introduzca un valor de compra minima valido';

    if(Object.keys(clickErrors).length !== 0){
      setErrors(clickErrors);
      return;
    }

    const dataDB = showedData.filter(item => item.userChecked === true).map(item => (
      {
        voucherOwner: item.userEmail,
        voucherDiscount,
        discountType,
        validDate: date,
        valid: true,
        userName: item.userShortName,
        userPhone: item.userPhone,
        sign,
        message: message.replace('{user}', firstUpperCase(item.userShortName)),
        channel,
        minPurchase
      }
    ))
    document.getElementById(`voucherDiscount`).disabled = true;
    const postErrors = await postGroupVoucher(dataDB);
    if(Object.keys(postErrors).length === 0){
      getData();
      setSuccess(true);
    }else{
      setErrors(postErrors);
    }
  }

  const handleKeyUp = (e) => {
    if(e.target.name === 'voucherDiscount'){
      if((e.keyCode >= 48 && e.keyCode <= 57)|| (e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode === 8) || (e.keyCode === 46) || (e.keyCode === 13) || (e.keyCode === 9)){
        if(e.target.classList.contains('input-error')){
          e.target.classList.remove('input-error');
        }
      }else{
        e.target.classList.add('input-error');
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        setTimeout(() => {
          e.target.classList.remove('input-error');
        }, 500)
      }
      if(e.keyCode === 13){
        handleClick();
      }
    }
  }

  const handleChange = (e) => {
    if(e.target.id === 'filter'){
      const usersLastOrder = showedData.map(item => (
        {
          ...item,
          lastOrder: item.orders.length > 0 ? new Date(item.orders[item.orders.length-1].createdAt).getTime() : new Date("2022-05-06T23:47:33.038+00:00").getTime(),
        }
      ))
      const data = usersLastOrder.filter(item => (Date.now() - item.lastOrder) > (2*7*24*60*60*1000) ); //ESE NUMERO SON 2 SEMANAS EN MILISEGUNDOS
      setShowedData(data);

    }else if(e.target.id === "all"){
      makeDataForFilter();
    }
    changeFilter ? setChangeFilter(false) : setChangeFilter(true); //para reiniciar los checks
  }

  const functionAfterSuccess = () => {
    setSuccessPopUp(false);
    setShowGroupTableBoolean(false);
  }

  useEffect(() => {
    for(let i = 0; i < showedData.length; i++){
      if(document.getElementById(`userChecked-${i}`)?.checked){
        document.getElementById(`userChecked-${i}`).checked = false;
      }
    }
  }, [changeFilter])

  useEffect(() => {
    if(allOrders.length !== 0 && users.length !== 0){
      makeDataForFilter();
    }
  }, [allOrders, users])

  useEffect(() => {
    const daysData = getDayData(15);
    setValidDates(daysData);
    setDefaultDate(daysData.find(item => item[1] === true)[0]);
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
        document.getElementById(`voucherDiscount`).disabled = false;
      }, 1000)
    }
  }, [errors])

  useEffect(() => {
    if(!errorPopUp && Object.keys(errors).length !== 0){
      setErrors({});
    }
  }, [errorPopUp])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      setSuccessPopUp(true);
      document.getElementById(`overlay`).focus();
      document.getElementById(`voucherDiscount`).disabled = false;
    }
  }, [success])

  useEffect(() => {
    if(!showGroupTableBoolean){
      document.getElementById(`all`).click();
      document.getElementById(`date`).value = defaultDate;
      document.getElementById(`discountType%`).checked = false;
      document.getElementById(`discountType$`).checked = false;
      document.getElementById(`voucherDiscount`).value = null;
      document.getElementById(`minPurchase`).value = null;
    }
  }, [showGroupTableBoolean])

  return (
    <>
      <div className="mb-3 ms-3">
        <Form.Check
          inline
          label="Todos los Usuarios"
          name="radio"
          id="all"
          type="radio"
          onChange={handleChange}
          defaultChecked
        />
        <Form.Check
          inline
          label="Usuarios sin pedidos durante las últimas 2 semanas"
          name="radio"
          id="filter"
          type="radio"
          onChange={handleChange}
        />
      </div>
      <VoucherFilteredTable showedData={showedData} setShowedData={setShowedData} changeFilter={changeFilter} showGroupTableBoolean={showGroupTableBoolean} />
      <div className='mt-5 w-100 mb-3'>
        <Form.Group>
          <Form.Label className='fw-bold'>Mensaje Para el Usuario (obligatorio)</Form.Label>
          <Form.Control placeholder='Redactar mensaje para usuario...' as="textarea" rows={3} onChange={(e) => setMessage(e.target.value)} value={message} />
        </Form.Group>
        <small>[{message.length}/400]</small>
        <div><small className='text-secondary fst-italic'>Aclaraciones: {'{user}: Nombre'}</small></div>
      </div>
      <Table striped bordered>
      <thead>
          <tr>
            <th className='text-center'>Usuarios</th>
            <th className='text-center'>Validez</th>
            <th className='text-center'>[%]</th>
            <th className='text-center'>[$]</th>
            <th className='text-center'>Valor</th>
            <th className='text-center'>Compra Mín.</th>
          </tr>
        </thead>
        <tbody>
          <tr id={`row`} className='position-relative'>
            <td>GRUPO SELECCIONADO</td>
            <td className='text-center'>
              <select name="validDate" className='select-style' id={`date`} defaultValue={defaultDate}>
                {
                  validDates?.map((date, index) => (
                    <option key={index} value={date[0]}>{date[0]}</option>
                  ))
                }
              </select>
            </td>
            <td className='text-center'>
              <input type="radio" name={`discountType`} id={`discountType%`} onChange={handleKeyUp} />
            </td>
            <td className='text-center'>
              <input type="radio" name={`discountType`} id={`discountType$`} onChange={handleKeyUp} />
            </td>
            <td className='text-center'>
              <input type="text" className='w-80' id={`voucherDiscount`} onKeyUp={handleKeyUp} name='voucherDiscount' />
            </td>
            <td className='text-center'>
              <input type="text" className='w-80' id={`minPurchase`} onKeyUp={handleKeyUp} name='minPurchase' />
            </td>
          </tr>
        </tbody>
      </Table>
      <div className='d-flex w-100 justify-content-center'>
        <Button className='w-100' variant='outline-dark' onClick={() => handleClick("email")} disabled={message.length < 10 || spinner}>Email</Button>
        <Button className='w-100 ms-5' variant='outline-dark' onClick={() => handleClick("whatsapp")} disabled={message.length < 10 || spinner}>Whatsapp</Button>
      </div>
      <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} closeBtn={true} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"} />
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Cupón de Descuento Enviado"} popUpText={"Cupón enviado a los Usuarios Seleccionados."} popUpBtnFunction={functionAfterSuccess} popUpBtnText={"OK"} noWayOfClose={true} />
    </>
  );
};

export default AddGroupVoucher;